import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import Form from './clienteForm';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import DateFormat from '../common/dateFormat/DateFormat';
import Switch from 'react-switch';
import ReactExport from 'react-export-excel-fixed-xlsx';

import {
	setModoTela, initForm, salvar, excluir, getLista, setFiltro, getRegistro,
	getListaClienteTipo, getListaClienteStatus, getListaEstadoCivil, getListaFormaPagamento, getListaOperadoraTelefonica,
	getListaTipoContrato, getListaUsuario, getListaEstado, getListaCidade, getListaDocumento, getListaMensagem, setMensagemLida
} from './clienteActions';

class Cliente extends Component {

	state = {
		pesquisar: '',
		criterio: true,
		mostraAtivos: true,
		mostraCancelados: false,
		mostrBoletosPendentes: true,
		mostraAdicionadoPeloApp: true,
		mostraReservasRealizadas: true,
		mostraPendentes: true,
		mostraCadastro: true,
		mostraAnuidade: true,
	}

	componentWillMount() {
		this.props.getLista(false);
		this.props.getListaClienteTipo();
		this.props.getListaClienteStatus();
		this.props.getListaEstadoCivil();
		this.props.getListaFormaPagamento();
		this.props.getListaOperadoraTelefonica();
		this.props.getListaTipoContrato();
		this.props.getListaUsuario();
		this.props.getListaEstado();
		this.props.getListaCidade();
		this.props.getListaMensagem();

		setInterval(() => {
			this.props.getListaMensagem();
		}, 20000);
	}

	render() {
		return (
			<div>
				
				
				<Content>
					{this.props.modoTela == 'visualizacao' ? (
						<Form visualizar />
					) : null}
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
				</Content>
			</div>
		)
	}

	lista() {

		let lista = this.props.lista.filter(item => item.id_tipo == 1).filter(item => {
			if (this.state.criterio && (!this.state.pesquisar || this.state.pesquisar.length < 4)) {
				return false;
			}
			return !this.state.pesquisar
				|| (`${item.id_tipo == 1 ? item.numero_contrato : item.numero_convite} ${item.nome} ${item.cpf} ${item.dependente1 || ''} ${item.dependente2 || ''} ${item.dependente3 || ''} ${item.nome_status}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())
				|| ((item.itens || []).filter(itemPgto => {
					return itemPgto.numero_cheque && itemPgto.numero_cheque.toUpperCase().includes(this.state.pesquisar.toUpperCase())
				}).length > 0);
		});


		let ativos = this.props.lista.filter(item => item.id_status == 1 || item.id_status == 7).length; //Ativo e Quitado
		let cancelados = this.props.lista.filter(item => item.id_status == 2 || item.id_status == 9).length; //Cancelado  || Inutilizado 
		let boletosPendentes = this.props.lista.filter(item => item.id_status == 3 || item.id_status == 13 || item.id_status == 14 
			|| item.id_status == 15 || item.id_status == 18 || item.id_status == 19 || item.id_status == 20 || item.id_status == 21 || item.id_status == 22 || item.id_status == 23 || item.id_status == 24).length; //Boleto Pendente || Boleto Juno Pendente || Juno | SCPC || Mensalidade Ano Anterior Aberto || Bloqueado com Débitos
		let adicionadosPeloApp = this.props.lista.filter(item => item.id_status == 4).length; //Adicionado pelo App
		let reservasRealizadas = this.props.lista.filter(item => item.id_status == 5).length; //Reserva Realizada
		let pendentes = this.props.lista.filter(item => item.id_status == 6 || item.id_status == 11 || item.id_status == 10).length; //Pendente || Financeiro  || Jurídico
		let cadastroPendente = this.props.lista.filter(item => item.id_status == 8).length;// || Cadastro Pendente
		let anuidade = this.props.lista.filter(item => item.id_status == 12 || item.id_status == 16 || item.id_status == 17).length;// || Anuidade em Aberto || Anuidade Ano Anterior || Bloqueado Temporariamente
		let total = this.props.lista.length;


		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		return (
			<>

				<Row>
					<Grid cols='4 4 3 2'style={{paddingLeft: '3px', paddingRigth: '5px'}}>
						<div class='info-box' style={{width: 170, minHeight: 75}}>
							<span class='info-box-icon' style={{ backgroundColor: '#a2b9bc', color: '#357c3c',height: 75, width:50, lineHeight: '75px' }}><i style={{ marginTop: -10 }} class='fa fa-check'></i></span>
							<div class='info-box-content' style={{width: 120, height: 70 , marginLeft: 50}}>
								<span class='info-box-text' style={{fontSize: 12, marginRight: 15}}>Ativo
									<button type='button' class='close' data-dismiss='alert' aria-hidden='true'
										onClick={() => alert('Status do Filtro:\nAtivo\nQuitado')}>
										<i class='fa fa-eye'></i>
									</button>
								</span>
								<span class='info-box-number'style={{ fontSize: 12, marginRight: 5}}>{ativos}</span>
								<div style={{ display: 'flex', justifyContent: 'space-between', height: 27,   width: 46}}>
									<span class='info-box-number'style={{ fontSize: 12, marginRight: 25}}>{total ? parseInt((ativos * 100) / total) : 0}<small>%</small></span>
									<Switch uncheckedIcon={null}   checkedIcon={null} onChange={() => {

										let status = this.props.filtro.status;

										if (this.state.mostraAtivos) {
											status = status.filter(item => item != 1 && item != 7);
										} else {
											status.push(1);
											status.push(7);
										}
										this.setState({ ...this.state, mostraAtivos: !this.state.mostraAtivos });

										this.props.setFiltro({
											...this.props.filtro,
											status: status
										});
										this.props.getLista();

									}} checked={this.state.mostraAtivos} />
								</div>
							</div>
						</div>
					</Grid>

					<Grid cols='4 4 3 2'style={{paddingLeft: '3px', paddingRigth: '5px'}}>
							<div class='info-box' style={{width: 170, minHeight: 75}}>
							<span class='info-box-icon ' style={{ backgroundColor: '#f9a825', color: '#fff', height: 75, width:50, lineHeight: '75px'   }}><i class='fa fa-dollar'></i></span>
							<div class='info-box-content' style={{width: 120, height: 70 , marginLeft: 50}}>
								<span class='info-box-text' style={{ fontSize: 12}}>Boleto 
									<button type='button' class='close' data-dismiss='alert' aria-hidden='true'
										onClick={() => alert('Status do Filtro:\nBoleto Pendente\nBoleto Juno Pendente\nJuno | SCPC\nMensalidade Ano Anterior Aberto')}>
										<i class='fa fa-eye'></i>
									</button>
								</span>
								<span class='info-box-number'style={{ fontSize: 12, marginRight: 5}}>{boletosPendentes}</span>
								<div style={{ display: 'flex', justifyContent: 'space-between', height: 27,   width: 46}}>
									<span class='info-box-number'style={{ fontSize: 12, marginRight: 25}}>{total ? parseInt((boletosPendentes * 100) / total) : 0}<small>%</small></span>
									<Switch uncheckedIcon={null} checkedIcon={null} onChange={() => {

										let status = this.props.filtro.status;

										if (this.state.mostrBoletosPendentes) {
											status = status.filter(item => item != 3 && item != 13 && item != 14 && item != 15 && item != 18 && item != 19 && item != 29 && item != 21 && item != 22 && item != 23 && item != 24);
										} else {
											status.push(3);
											status.push(13);
											status.push(14);
											status.push(15);
											status.push(18);
											status.push(19);
											status.push(20);
											status.push(21);
											status.push(22);
											status.push(23);
											status.push(24);
										}
										this.setState({ ...this.state, mostrBoletosPendentes: !this.state.mostrBoletosPendentes });

										this.props.setFiltro({
											...this.props.filtro,
											status: status
										});
										this.props.getLista();

									}} checked={this.state.mostrBoletosPendentes}  />
								</div>
							</div>
						</div>
					</Grid>

					<Grid cols='4 4 3 2' style={{paddingLeft: '3px', paddingRigth: '5px'}}>
							<div class='info-box' style={{width: 170, minHeight: 75}}>
							<span class='info-box-icon ' style={{ backgroundColor: '#f9a825', color: '#fff' ,height: 75, width:50, lineHeight: '75px'  }}><i class='fa fa-spinner'></i></span>
							<div class='info-box-content' style={{width: 120, height: 70 , marginLeft: 50}}>
								<span class='info-box-text' style={{ fontSize: 12}}>Pendente
									<button type='button' class='close' data-dismiss='alert' aria-hidden='true'
										onClick={() => alert('Status do Filtro:\nPendente\nFinanceiro\nJurídico')}>
										<i class='fa fa-eye'></i>
									</button>
								</span>
								<span class='info-box-number'style={{ fontSize: 12, marginRight: 5}}>{pendentes}</span>
							<div style={{ display: 'flex', justifyContent: 'space-between', height: 27,   width: 46}}>
									<span class='info-box-number'style={{ fontSize: 12, marginRight: 25}}>{total ? parseInt((pendentes * 100) / total) : 0}<small>%</small></span>
									<Switch uncheckedIcon={null} checkedIcon={null} onChange={() => {

										let status = this.props.filtro.status;

										if (this.state.mostraPendentes) {
											status = status.filter(item => item != 6 && item != 11 && item != 8 && item != 10);
										} else {
											status.push(6);
											status.push(11);
											status.push(8);
											status.push(10);
										}
										this.setState({ ...this.state, mostraPendentes: !this.state.mostraPendentes });

										this.props.setFiltro({
											...this.props.filtro,
											status: status
										});
										this.props.getLista();

									}} checked={this.state.mostraPendentes} />
								</div>
							</div>
						</div>
					</Grid>
					<Grid cols='4 4 3 2'style={{paddingLeft: '3px', paddingRigth: '5px'}}>
							<div class='info-box' style={{width: 170, minHeight: 75}}>
							<span class='info-box-icon ' style={{ backgroundColor: '#f9a825', color: '#fff', height: 75, width:50, lineHeight: '75px'   }}><i class='fa fa-pencil-square-o'></i></span>
							<div class='info-box-content' style={{width: 120, height: 70 , marginLeft: 50}}>
								<span class='info-box-text' style={{ fontSize: 12}}> Cadastro Pendente
								</span>
								<span class='info-box-number'style={{ fontSize: 12, marginRight: 5}}>{cadastroPendente}</span>
							<div style={{ display: 'flex', justifyContent: 'space-between', height: 27,   width: 46}}>
									<span class='info-box-number'style={{ fontSize: 12, marginRight: 25}}>{total ? parseInt((cadastroPendente * 100) / total) : 0}<small>%</small></span>
									<Switch uncheckedIcon={null} checkedIcon={null} onChange={() => {

										let status = this.props.filtro.status;

										if (this.state.mostraCadastro) {
											status = status.filter(item => item != 8);
										} else {
											status.push(8);

										}
										this.setState({ ...this.state, mostraCadastro: !this.state.mostraCadastro });

										this.props.setFiltro({
											...this.props.filtro,
											status: status
										});
										this.props.getLista();

									}} checked={this.state.mostraCadastro} />
								</div>
							</div>
						</div>
					</Grid>


					<Grid cols='4 4 3 2'style={{paddingLeft: '3px', paddingRigth: '5px'}}>
							<div class='info-box' style={{width: 170, minHeight: 75}}>
							<span class='info-box-icon ' style={{ backgroundColor: '#f9a825', color: '#fff',height: 75, width:50, lineHeight: '75px'   }}><i class='fa fa-calendar'></i></span>
							<div class='info-box-content' style={{width: 120, height: 70 , marginLeft: 50}}>
								<span class='info-box-text' style={{ fontSize: 12}}> Anuidade
									<button type='button' class='close' data-dismiss='alert' aria-hidden='true'
										onClick={() => alert('Status do Filtro:\nAnuidade em Aberto\nAnuidade Ano Anterior')}>
										<i class='fa fa-eye'></i>
									</button>
								</span>
								<span class='info-box-number'style={{ fontSize: 12, marginRight: 5}}>{anuidade}</span>
							<div style={{ display: 'flex', justifyContent: 'space-between', height: 27,   width: 46}}>
									<span class='info-box-number'style={{ fontSize: 12, marginRight: 25}}>{total ? parseInt((anuidade * 100) / total) : 0}<small>%</small></span>
									<Switch uncheckedIcon={null} checkedIcon={null} onChange={() => {

										let status = this.props.filtro.status;

										if (this.state.mostraAnuidade) {
											status = status.filter(item => item != 12 && item != 16 && item != 17);
										} else {
											status.push(12);
											status.push(16);
											status.push(17);

										}
										this.setState({ ...this.state, mostraAnuidade: !this.state.mostraAnuidade });

										this.props.setFiltro({
											...this.props.filtro,
											status: status
										});
										this.props.getLista();

									}} checked={this.state.mostraAnuidade} />
								</div>
							</div>
						</div>
					</Grid>
				

					<Grid cols='4 4 3 2'style={{paddingLeft: '3px', paddingRigth: '5px'}}>
							<div class='info-box' style={{width: 170, minHeight: 75}}>
							<span class='info-box-icon' style={{ backgroundColor: '#c62828', color: '#fff' , height: 75, width:50, lineHeight: '75px'  }}><i class='fa fa-ban'></i></span>
							<div class='info-box-content' style={{width: 120, height: 70 , marginLeft: 50}}>
								<span class='info-box-text' style={{ fontSize: 12}}>Cancelado
									<button type='button' class='close' data-dismiss='alert' aria-hidden='true'
										onClick={() => alert('Status do Filtro:\nCancelado\nInutilizado')}>
										<i class='fa fa-eye'></i>
									</button>
								</span>
								<span class='info-box-number'style={{ fontSize: 12}}>{cancelados}</span>
							<div style={{ display: 'flex', justifyContent: 'space-between', height: 27,   width: 46}}>
									<span class='info-box-number'style={{ fontSize: 12, marginRight: 25}}>{total ? parseInt((cancelados * 100) / total) : 0}<small>%</small></span>
									<Switch uncheckedIcon={null} checkedIcon={null} onChange={() => {

										let status = this.props.filtro.status;

										if (this.state.mostraCancelados) {
											status = status.filter(item => item != 2 && item != 9);
										} else {
											status.push(2);
											status.push(9);

										}
										this.setState({ ...this.state, mostraCancelados: !this.state.mostraCancelados });

										this.props.setFiltro({
											...this.props.filtro,
											status: status
										});
										this.props.getLista();

									}} checked={this.state.mostraCancelados} />
								</div>
							</div>
						</div>
					</Grid>

					

				</Row>

				{this.props.listaMensagem.map(item => {
					let valorColuna = [];
					return (
						<div style={{ display: 'flex' }} class='alert alert-info alert-dismissible' key={item.id}>
							{item.texto.split('\n').map((texto,index) => {
							
								if (index > 0 && (index +1 ) % 3 === 0  ) {
								
									const divColuna = <div style={{ width: "30%" }}>{valorColuna}</div>;
									valorColuna = [<p>{texto}</p>];
									return divColuna;
								} 
								let telefone = texto.includes('Contato:') || texto.includes('Telefone:') ? texto.split(":")[1] : null;
								if (telefone) {
									telefone = telefone.replace(/\D/g, '').trim();
									if (telefone.substring(0, 1) == '0') {
										telefone = telefone.substring(2, telefone.length);
									}
									if (telefone.length <= 8) {
										telefone = `9${telefone}`;
									}
									if (telefone.length <= 9) {
										telefone = `55${telefone}`;
									}
								}
								
									valorColuna.push(<p>{texto}
										{texto.includes('Contato:') || texto.includes('Telefone:') ? (
											<a href={`https://api.whatsapp.com/send?phone=+55${telefone}`} target='_blank'>
												<ButtonTable type={'success'} icon={'fa fa-whatsapp'} />
											</a>
										) : null} 
									
										</p>)
								
							})}
							<div style={{ width: "10%" }}>
								<button type='button' class='close' data-dismiss='alert' aria-hidden='true' onClick={() => this.props.setMensagemLida(item)}>Lido</button>
							</div>
						</div>
					);
				})}

				<ContentCard>
					<BoxButtonHeader>
						<Row>
							<Grid cols='6 6 2 1'>
								<Button
									text='Adicionar'
									style={{ marginLeft: -15 }}
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('cadastro', {
											id_tipo: 1,
											data_venda: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
										});
										this.props.initForm({
											id_tipo: 1,
											data_venda: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
										});

										this.props.getListaDocumento(null);
									}} />
							</Grid>

							<Grid cols='6 6 2 1'>
								<ExcelFile
									filename={'Cliente'}
									element={
										<Button
											text='Exportar'
											type={'primary'}
											icon={'fa fa-download'} />
									}>
									<ExcelSheet data={lista} name='Cliente'>
										<ExcelColumn label='Número' value='numero_contrato' />
										<ExcelColumn label='Nome' value='nome' />
										<ExcelColumn label='Telefone' value='telefone_fixo' />
										<ExcelColumn label='Celular' value='celular1' />
										<ExcelColumn label='Email' value='email' />

									</ExcelSheet>
								</ExcelFile>
							</Grid>
						</Row>
					</BoxButtonHeader>

					<Row>
						<LabelAndInputMask
							label='Data Inicial'
							placeholder='Informe a Data Inicial'
							mask={'99/99/9999'}
							cols='12 4 3'
							value={this.props.filtro.dataInicial}
							onChange={data => {
								this.props.setFiltro({
									...this.props.filtro,
									dataInicial: data.target.value
								});

							}} />
						<LabelAndInputMask
							label='Data Final'
							placeholder='Informe a Data Final'
							mask={'99/99/9999'}
							cols='12 4 3'
							value={this.props.filtro.dataFinal}
							onChange={data => {
								this.props.setFiltro({
									...this.props.filtro,
									dataFinal: data.target.value
								});

							}} />

						<Grid cols='6 6 2 1'>
							<Button
								style={{ marginTop: 25 }}
								text='Atualizar'
								type={'primary'}
								icon={''}
								event={() => {
									this.props.getLista();
								}} />
						</Grid>
					</Row>
					<Row>
						<Select
							name='id_vendedor'
							options={this.props.listaUsuario}
							label='Vendedor'
							cols='12 4 3'
							placeholder='Selecione o Vendedor'
							value={this.props.filtro.id_vendedor}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_vendedor: value
								});
								this.props.getLista(false);
							}} />

						<Select
							name='id_representante'
							options={this.props.listaUsuario}
							label='Representante'
							cols='12 4 3'
							placeholder='Selecione o Representante'
							value={this.props.filtro.id_representante}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_representante: value
								});
								this.props.getLista(false);
							}} />
						<Select
							name='id_cidade'
							options={this.props.listaCidade}
							label='Cidade'
							cols='12 4 3'
							placeholder='Selecione o Representante'
							value={this.props.filtro.id_cidade}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_cidade: value
								});
								this.props.getLista(false);
							}} />
					</Row>
					<Row>
						<Grid cols='8 8 8 9'>
							<input className='form-control'
								placeholder='Pesquisar (digite pelo menos 4 caracteres para iniciar a pesquisa)'
								type='text'
								value={this.state.pesquisar}
								onChange={e => {
									this.setState({ ...this.state, pesquisar: e.target.value });
								}} />
						</Grid>
						<Select
							options={[{ id: 1, valor: 'Listar pela pesquisa' }, { id: 2, valor: 'Listar todos' }]}
							label=''
							cols='4 4 4 3'
							placeholder='Selecione o critério'
							value={this.state.criterio ? 1 : 2}
							onChange={value => {
								this.setState({
									...this.state,
									criterio: value == 1
								});
							}} />
					</Row>
					<Table>
						<THead>
							<Tr>
								<Th>Data / Hora</Th>
								<Th alignCenter>Status</Th>
								<Th>Tipo</Th>
								<Th>Número</Th>
								<Th>Nome</Th>
								<Th>CPF</Th>
								<Th>Forma Pagamento</Th>
								<Th alignCenter acoes>Ações</Th>
							</Tr>
						</THead>
						<TBody>

							{lista.map(item => (
								<Tr key={item.id}>
									<Td>{DateFormat.formatarDataSqlParaTela(item.data_venda)}</Td>
									<Td alignCenter style={{ color: item.cor_status, fontWeight: 'bold' }}>{item.nome_status}</Td>
									<Td style={{ color: !item.id_tipo_contrato ? '#f00' : null }}>{!item.id_tipo_contrato ? '[VERIFICAR] - ' : ''}{item.id_tipo == 1 ? 'Contrato' : 'Convite'}</Td>
									<Td>{item.id_tipo == 1 ? item.numero_contrato : item.numero_convite}</Td>
									<Td>{item.nome}</Td>
									<Td>{item.cpf}</Td>
									<Td>{item.nome_forma_pagamento}</Td>
									<Td alignCenter>
										<ButtonTable
											type={'primary'}
											icon={'fa fa-eye'}
											event={() => {
												this.props.setModoTela('visualizacao', {
													...item,
													data_venda: DateFormat.formatarDataSqlParaTela(item.data_venda),
													data_nascimento: item.data_nascimento ? DateFormat.formatarDataSqlParaTela(item.data_nascimento) : null,
													data_serasa: DateFormat.formatarDataSqlParaTela(item.data_serasa),
													data_scpc: DateFormat.formatarDataSqlParaTela(item.data_scpc),
													itens: item.itens.map(itemPgto => ({
														...itemPgto,
														data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento),
														data_pagamento: DateFormat.formatarDataSqlParaTela(item.data_pagamento)
													}))
												});
												this.props.initForm({
													...item,
													data_venda: DateFormat.formatarDataSqlParaTela(item.data_venda),
													data_nascimento: item.data_nascimento ? DateFormat.formatarDataSqlParaTela(item.data_nascimento) : null,
													data_serasa: DateFormat.formatarDataSqlParaTela(item.data_serasa),
													data_scpc: DateFormat.formatarDataSqlParaTela(item.data_scpc),
													itens: item.itens.map(itemPgto => ({
														...itemPgto,
														data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento),
														data_pagamento: DateFormat.formatarDataSqlParaTela(item.data_pagamento)
													}))
												});

												this.props.getRegistro(item.id);

												this.props.getListaDocumento(item.id);
											}} />
										<ButtonTable
											type={'warning'}
											icon={'fa fa-pencil'}
											event={() => {
												console.log(item);
												this.props.setModoTela('cadastro', {
													...item,
													data_venda: DateFormat.formatarDataSqlParaTela(item.data_venda),
													data_nascimento: item.data_nascimento ? DateFormat.formatarDataSqlParaTela(item.data_nascimento) : null,
													data_serasa: DateFormat.formatarDataSqlParaTela(item.data_serasa),
													data_scpc: DateFormat.formatarDataSqlParaTela(item.data_scpc),
													itens: item.itens.map(itemPgto => ({
														...itemPgto,
														data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento),
														data_pagamento: DateFormat.formatarDataSqlParaTela(item.data_pagamento)
													}))
												});
												this.props.initForm({
													...item,
													data_venda: DateFormat.formatarDataSqlParaTela(item.data_venda),
													data_nascimento: item.data_nascimento ? DateFormat.formatarDataSqlParaTela(item.data_nascimento) : null,
													data_serasa: DateFormat.formatarDataSqlParaTela(item.data_serasa),
													data_scpc: DateFormat.formatarDataSqlParaTela(item.data_scpc),
													itens: item.itens.map(itemPgto => ({
														...itemPgto,
														data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento),
														data_pagamento: DateFormat.formatarDataSqlParaTela(item.data_pagamento)
													}))
												});
												
												this.props.getRegistro(item.id);

												this.props.getListaDocumento(item.id);
											}} />
										<ButtonTable
											type={'danger'}
											icon={'fa fa-trash-o'}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													data_venda: DateFormat.formatarDataSqlParaTela(item.data_venda),
													data_nascimento: item.data_nascimento ? DateFormat.formatarDataSqlParaTela(item.data_nascimento) : null,
													data_serasa: DateFormat.formatarDataSqlParaTela(item.data_serasa),
													data_scpc: DateFormat.formatarDataSqlParaTela(item.data_scpc),
													itens: item.itens.map(itemPgto => ({
														...itemPgto,
														data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento),
														data_pagamento: DateFormat.formatarDataSqlParaTela(item.data_pagamento)
													}))
												});
												this.props.initForm({
													...item,
													data_venda: DateFormat.formatarDataSqlParaTela(item.data_venda),
													data_nascimento: item.data_nascimento ? DateFormat.formatarDataSqlParaTela(item.data_nascimento) : null,
													data_serasa: DateFormat.formatarDataSqlParaTela(item.data_serasa),
													data_scpc: DateFormat.formatarDataSqlParaTela(item.data_scpc),
													itens: item.itens.map(itemPgto => ({
														...itemPgto,
														data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento),
														data_pagamento: DateFormat.formatarDataSqlParaTela(item.data_pagamento)
													}))
												});

												this.props.getRegistro(item.id);

												this.props.getListaDocumento(item.id);
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCard>
			</>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.cliente.modoTela,
	lista: state.cliente.lista,
	listaMensagem: state.cliente.listaMensagem,
	filtro: state.cliente.filtro,
	listaUsuario: state.cliente.listaUsuario,
	filtro: state.cliente.filtro,
	listaCidade: state.cliente.listaCidade,
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, setFiltro, getRegistro, getListaClienteTipo, getListaClienteStatus, getListaEstadoCivil, getListaFormaPagamento, getListaOperadoraTelefonica, getListaTipoContrato, getListaUsuario, getListaEstado, getListaCidade, getListaDocumento, getListaMensagem, setMensagemLida }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Cliente);
